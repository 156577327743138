import logo from "./logo.png"
const clientData = {
    client_entity: 56,
    attorney_firm: 'Coleman Legal Group',
    attorney_name: 'Lee Coleman',
    attorney_number: '270-799-0066',
    attorney_email: 'lcoleman@colemanlegal.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_coleman_legal_group+(720p).mp4',
    mainColor: '#242424',
    secondaryColor: '#0c1d3d',
    siteLink: 'http://colemanlegal.com/',
    logo
}

export default clientData